import {createReducer, on} from '@ngrx/store';
import * as SiteMapActions from './sitenav.actions';
import {
    BasePage,
    ModuleValidation,
    PageNotFound,
} from '../../@core/generated/operations-cms-graphql';
import {environment} from 'src/environments/environment';

export interface SiteMapBase extends BasePage {
    childIds?: (number | null)[] | null | undefined;
    id?: any;
    parentId?: any;
    sortOrder?: any;
    key?: any;
}

export interface SiteMapState {
    ProtocolPageSiteMap: ProtocolPageSiteMap[];
    ProtocolPages: (SiteMapBase | null)[];
    ModuleValidation: (ModuleValidation | null)[];
    moduleStatus: string | null;
    PageErrorInfo: (PageNotFound | null)[];
    protocolRootKey: string | null;
    ProtocolMenuActive: string | "";
    RouteParams: any | null;
}

const initialState: SiteMapState = {
    ProtocolPageSiteMap: [],
    ProtocolPages: [],
    ModuleValidation: [],
    moduleStatus: null,
    PageErrorInfo: [],
    protocolRootKey: environment.rootNavContentKey,
    ProtocolMenuActive: '',
    RouteParams: null,
};

export const siteMapReducer = createReducer<SiteMapState>(
    initialState,
    on(SiteMapActions.ProtocolMenuSelected, (state, action): SiteMapState => {
        return {
            ...state,
            ProtocolMenuActive: action.ProtocolMenuActive,
        };
    }),
    on(SiteMapActions.SiteMapLoad, (state, action): SiteMapState => {
        return {
            ...state,
            moduleStatus: ""
        };
    }),
    on(SiteMapActions.SwitchMapRootLoad, (state, action): SiteMapState => {
        return {
            ...state,
        };
    }),
    on(SiteMapActions.SiteMapSuccess, (state, action): SiteMapState => {
        let hierarchy: ProtocolPageSiteMap[] = [];
        action.contentRootKeys?.forEach((p) => {
            if (p?.parentId === -1) {
                const siteMapRoot: ProtocolPageSiteMap = {Node: p};
                hierarchy.push(siteMapH(siteMapRoot, p, action.contentRootKeys || []));
            }
        });

        return {
            ...state,
            ProtocolPages: action.contentRootKeys || [],
            ProtocolPageSiteMap: hierarchy,
            moduleStatus: ""
        };
    }),
    on(SiteMapActions.ModuleValidationLoad, (state, action): SiteMapState => {
        return {
            ...state,
        };
    }),
    on(SiteMapActions.ModuleValidationSuccess, (state, action): SiteMapState => {
        return {
            ...state,
            ModuleValidation: action.moduleValidation || [],
        };
    }),
    on(SiteMapActions.PageErrorInfoLoad, (state, action): SiteMapState => {
        return {
            ...state,
        };
    }),
    on(SiteMapActions.PageErrorInfoSuccess, (state, action): SiteMapState => {
        return {
            ...state,
            PageErrorInfo: action.pageErrorInfo || [],
        };
    }),
    on(SiteMapActions.PageErrorInfoStatus, (state, action): SiteMapState => {
        let modulestatus = '';
        let hasAccess = state.ModuleValidation.find((e) =>
            e?.hasAccess?.find((a) => a?.key == action.moduleKey || a?.name.toLocaleLowerCase() == action.name?.toLocaleLowerCase())
        );

        let allModules = state.ModuleValidation.find((e) =>
            e?.allModules?.find((a) => a?.key == action.moduleKey || a?.name.toLocaleLowerCase() == action.name?.toLocaleLowerCase())
        );

        if (hasAccess === undefined && allModules === undefined) {
            modulestatus = "404";
        } else {
            if (allModules && hasAccess) {
                modulestatus = "";
            } else {
                modulestatus = "401";
            }
        }

        return {
            ...state,
            moduleStatus: modulestatus,
        };
    })
);

export interface SiteMapIcon {
    name: string;
    data: string;
}

export interface ProtocolPageSiteMap {
    Node?: SiteMapBase;
    Children?: ProtocolPageSiteMap[];
}

export function siteMapH(
    siteMap: ProtocolPageSiteMap,
    page: SiteMapBase,
    allPages: (SiteMapBase | null)[]
): ProtocolPageSiteMap {
    if (page.childIds && page.childIds?.length > 0) {
        siteMap.Children = [];
        page.childIds.forEach((childId) => {
            const childPage = allPages.find((e) => e?.id === childId);
            if (childPage && childPage.parentId === page.id) {
                const siteMapChildren: ProtocolPageSiteMap = {Node: childPage};
                siteMap.Children?.push(siteMapH(siteMapChildren, childPage, allPages));
            }
        });
    }
    return siteMap;
}
