import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../state/app.state';
import { ContentService } from './services/content.service';
import { protocolsActions, protocolsSelectors } from '../modules/protocols/state';
import { PageNavigationService } from './services/page-navigation.service';

@Injectable({
	providedIn: 'root'
})
export class RefetchDataGuard implements CanActivateChild {
	constructor(private store: Store<State>, private contentService: ContentService, private pageNav: PageNavigationService,) { }

	protocolState = this.store.selectSignal(protocolsSelectors.selectCurrentProtocol);
	organizationState = this.store.selectSignal(protocolsSelectors.selectCurrentOrganizationDetails);

	canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {

		const params = childRoute.params;

		const protocolId = params['protocolId'];
		const organizationId = params['organizationId'];

		if (this.protocolState() == null && protocolId) {
			this.store.dispatch(protocolsActions.getProtocolDetail({ input: { protocolId: protocolId } }));
		}
		if (this.organizationState() == null && organizationId) {
			this.store.dispatch(protocolsActions.getCurrentOrganizationDetails({ variables: { organizationId: organizationId, protocolId: protocolId } }));
		}

		return true;
	}
}


