import { NgModule } from '@angular/core';
import { RouterModule, ROUTES, Routes } from '@angular/router';
import {
    TrimbleIdentityCallbackComponent
} from './@auth/components/trimble-identity-callback/trimble-identity-callback.component';
import { TrimbleAuthLogoutComponent } from './@auth/components/trimble-auth-logout/trimble-auth-logout..component';
import { authenticatedGuard, newUserGuard } from './@auth/auth-guard.service';
import { RefetchDataGuard } from './@core/refetch-data-guard';

const routes: Routes = [
    { path: 'login/callback', component: TrimbleIdentityCallbackComponent },
    { path: 'auth/logout', component: TrimbleAuthLogoutComponent },
    {
        path: 'modules',
        canActivateChild: [newUserGuard(), RefetchDataGuard],
        loadChildren: () => import('./modules/modules.module')
            .then(m => m.ModulesModule)
    },
    {
        path: 'invitation',
        canActivateChild: [authenticatedGuard()],
        loadChildren: () => import('./modules/users-invitation/users-invitation.module')
            .then(m => m.UsersInvitationModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false })],
    exports: [RouterModule],
    providers: [
        {
            provide: ROUTES,
            useFactory: () => {
                const dynamicRoutes: Routes = [];
                // add more routes
                return [
                    ...dynamicRoutes,
                    ...routes,
                    ...[
                        { path: '', redirectTo: 'modules', pathMatch: 'full' },
                        { path: '**', redirectTo: 'modules' }
                    ]
                ]
            },
            multi: true
        }
    ]
})
export class AppRoutingModule {
}
